@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* @apply bg-slate-200 */
  @apply bg-white

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.section{
  height: calc(100%-70px);
  width: 100%;
  background-color: white;
}


.custom-arrow {
  background: none !important;
}

.next-arrow::before, .prev-arrow::before {
  color: black !important;
}
.next-arrow2::before, .prev-arrow2::before {
  color: white !important;
}

.slizck-slide > div{
  margin: 0 10px;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.drop-shadow {
  filter: drop-shadow(1px 12px 10px #6B7280);
}


.product-description ul {
  list-style-type: disc !important;
  padding-left: 20px !important;
}

